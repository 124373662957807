import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Routes,
} from "react-router-dom";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import VendorManagement from "./pages/Vendor-Management/VendorManagement";
import AddVendor from "./pages/Vendor-Management/AddVendor";
import AddSubVendor from "./pages/Vendor-Management/AddSubVendor";
import EditVendor from "./pages/Vendor-Management/EditVendor";
import TaskManagement from "./pages/TasksManagement/TaskManagement";
import AddTaskVendor from "./pages/TasksManagement/AddTaskVendor";
import CreateTask from "./pages/TasksManagement/CreateTask";
import TeamManagement from "./pages/TeamManagement/TeamManagement";
import CreateTeam from "./pages/TeamManagement/CreateTeam";
import Calendar from "./pages/Calendar/Calendar";
import AddCalendarEvent from "./pages/Calendar/AddCalendarEvent";
import Notifications from "./pages/Notifications/Notifications";
import CreateNotification from "./pages/Notifications/CreateNotification";
import CreateAlert from "./pages/Notifications/CreateAlert";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />

          {/* Vendor Management Routes */}
          <Route path="/vendor-management" element={<VendorManagement />} />
          <Route path="/vendor-management/add-vendor" element={<AddVendor />} />
          <Route
            path="/vendor-management/edit-vendor"
            element={<EditVendor />}
          />
          <Route
            path="/vendor-management/add-subvendor"
            element={<AddSubVendor />}
          />

          {/* Task Management Routes */}
          <Route path="/task-management" element={<TaskManagement />} />
          <Route
            path="/task-management/vendor-task"
            element={<AddTaskVendor />}
          />
          <Route
            path="/task-management/vendor-task/create-task"
            element={<CreateTask />}
          />

          {/* Task Management Routes */}
          <Route path="/team-management" element={<TeamManagement />} />
          <Route path="/team-management/create-team" element={<CreateTeam />} />

          {/* Task Management Routes */}
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/calendar/add-event" element={<AddCalendarEvent />} />

          {/* Notificatons and alerts Routes */}
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/notifications/add-notification" element={<CreateNotification />} />
          <Route path="/notifications/add-alert" element={<CreateAlert />} />



          <Route path="/vendor-management" element={<VendorManagement />} />
          <Route path="/vendor-management" element={<VendorManagement />} />
          <Route path="/vendor-management" element={<VendorManagement />} />
          <Route path="/vendor-management" element={<VendorManagement />} />
          <Route path="/vendor-management" element={<VendorManagement />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
