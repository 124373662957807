import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import MainSection from "../../components/mainLayout/Main";
import { DatePicker, Space } from "antd";

const onChange = (date, dateString) => {
  console.log(date, dateString);
};

function AddCalendarEvent() {
  return (
    <div>
      <Sidebar />

      <MainSection>
        <SectionHeader title="Add Event" />

        <div className="container w-full md:w-1/2 lg:w-1/3  text-start">
          <div class="my-5">
            <label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 "
            >
              Event Title
            </label>
            <input
              type="text"
              id="email"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder="Enter Vendor name"
              required
            />
          </div>

          <div class="mb-5">
            <label for="" class="block mb-2 text-sm font-medium text-gray-900 ">
              Event Date
            </label>
            <DatePicker
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              onChange={onChange}
            />
          </div>

          <div class="my-5">
            <label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 "
            >
              Description
            </label>
            <textarea
              type="text"
              id="email"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder="Enter Description"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-[#045B9B] hover:bg-[#3385c0] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Create
          </button>
        </div>
      </MainSection>
    </div>
  );
}

export default AddCalendarEvent;
