import Sidebar from "../../components/sidebar/Sidebar";
import React, { useEffect, useState } from "react";
import $ from "jquery"; // Import jQuery
import "datatables.net-dt/css/dataTables.dataTables.min.css";
import "datatables.net"; // Import DataTables
import MainSection from "../../components/mainLayout/Main";
import SearchBar from "../../components/searchbar/SearchBar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import ToggleSwitch from "../../components/toggleSwitch/ToggleSwitch";
import DatatableSection from "../../components/datatableSection/DatatableSection";

const data = [];
for (let i = 1; i <= 100; i++) {
  // Add more rows for demo
  data.push({
    key: i,
    name: `John Brown ${i}`,
    age: Number(`${i}2`),
    address: `New York No. ${i} Lake Park`,
  });
}

const TeamManagement = () => {
  // Initialize DataTable on mount
  useEffect(() => {
    // Initialize DataTable after the component has mounted
    $("#vendorTable").DataTable({
      paging: true,
      searching: true,
      ordering: true,
      pageLength: 10,
      lengthMenu: [10, 20, 30, 50],
    });

    // Cleanup function to destroy the DataTable when the component unmounts
    return () => {
      $("#vendorTable").DataTable().destroy();
    };
  }, []);

  return (
    <>
      <div>
        <Sidebar />
      </div>

      <MainSection>
        <SectionHeader title="Vendors Name" />
        <SearchBar
          titleOne="Create Team"
          addVendorPath="/team-management/create-team"
          showEditButton={false}
        />

        {/* Table structure for jQuery DataTable */}

        <DatatableSection>
          <table id="vendorTable" className="display">
            <thead>
              <tr>
                <th>Name</th>
                <th>Age</th>
                <th>Address</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.age}</td>
                  <td>{item.address}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </DatatableSection>
      </MainSection>
    </>
  );
};

export default TeamManagement;
